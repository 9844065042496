



















import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { CoursePostOutline } from '../../../../types/typescript-axios/api';

@Component
export default class CourseBox extends Vue {
  @Prop({default: ''})
  private title!: string;

  @Prop({default: () => []})
  private data!: CoursePostOutline;
}
